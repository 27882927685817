const RESTAURANT = "Restaurante / Farmacia / Supermercado";
const ECOMMERCE = "E-commerce";
const STORE = "Retail";
const BUSINESS_LOCAL = "Negocio";
const OTHER = "Otros";

const NUM_RESTAURANT = 0;
const NUM_ECOMMERCE = 1;
const NUM_BUSINESS_LOCAL = 3;
const NUM_STORE = 2;
const NUM_OTHER = 4;

const get = {};
get[NUM_RESTAURANT] = RESTAURANT;
get[NUM_ECOMMERCE] = ECOMMERCE;
get[NUM_BUSINESS_LOCAL] = OTHER;
get[NUM_STORE] = STORE;
get[NUM_OTHER] = OTHER;

const options = [
  { value: NUM_RESTAURANT, text: RESTAURANT },
  { value: NUM_ECOMMERCE, text: ECOMMERCE },
  { value: NUM_STORE, text: STORE },
  { value: NUM_OTHER, text: OTHER }
];

export default {
  get,
  options,
  NUM_RESTAURANT,
  NUM_ECOMMERCE,
  NUM_STORE,
  NUM_BUSINESS_LOCAL,
  NUM_OTHER
};
