<template>
  <form-create-client selection-disabled @close="$router.go(-1)" />
</template>

<script>
import ClientTypes from "@zubut/common/src/constants/clients/type";
import FormCreateClient from "@/app/views/ClientCreate/FormCreateClient";
import * as mutation from "@/store/modules/clients/mutations-types";

export default {
  name: "ClientCreateBranchMobile",

  components: {
    FormCreateClient
  },

  data() {
    return {
      parentCompany: this.$route.params.id
    };
  },

  created() {
    this.$store.commit(
      `clients/${mutation.SET_CLIENT_TYPE}`,
      ClientTypes.NUM_BRANCH
    );
    this.$store.commit(
      `clients/${mutation.SET_PARENT_COMPANY}`,
      this.parentCompany
    );
  }
};
</script>

<style lang="scss" scoped></style>
